import img1 from '../images/item-background/home_page_sub.webp';
import imgbg1 from '../images/slider/bg_slide_1.png'

const heroSliderData = [
      {
        title_1: "Discover, find,",
        title_2: "Sell extraordinary",
        title_3: "Monster NFTs",
        description: "Marketplace for monster character cllections non fungible token NFTs",
        img: img1,
        imgbg: imgbg1,
        class:'left'
    }
];

export default heroSliderData;
