/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import routes from "./pages/index";
import { connect } from "react-redux";
import Login from "./pages/Login";
import { useMetamask, useAddress, useNetworkMismatch} from "@thirdweb-dev/react";
import Web3 from "web3";
import { loginSuccess } from "./actions";
import HomePage from "./pages/HomePage";

const App = (props) => {
  const provider = window.ethereum;
  const address = useAddress();
  const isMismatched = useNetworkMismatch()
  const connectWithMetamask = useMetamask();

  useEffect(() => {
    if (isMismatched) {
      (async () => {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x5" }],
        });
      })();
    }
  }, [isMismatched])

  useEffect(() => {
    const checkConnection = async () => {
      // Check if browser is running Metamask
      let web3;
      if (window.ethereum) {
          web3 = new Web3(window.ethereum);
      } else if (window.web3) {
          web3 = new Web3(window.web3.currentProvider);
      };
      const accounts = await web3.eth.getAccounts()
      if(accounts.length === 0){
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        window.location.reload();
        //connectWithMetamask();
      }
  };
  checkConnection();
  }, []);


  useEffect(()=>{
    console.log(address)
  },[address])

  return (
    <Routes>
      {
        (address && !isMismatched) ?
          routes.map((data, index) => (
            <Route
              onUpdate={() => window.scrollTo(0, 0)}
              exact={true}
              path={data.path}
              element={data.component}
              key={index}
            />
          )) : <>
            <Route path="/create-item" element={<Login />} />
            <Route path="/wallet-connect" element={<Login />} />
            <Route path="/*" element={<HomePage />} />
          </>}
    </Routes>
  );
};

export default connect(
  ({ auth }) => ({ auth: auth })
)(App);
