const menus = [
  {
    id: 1,
    name: "Direct Buy",
    links: "/direct-buy",
  },
  {
    id: 2,
    name: "Live Auction",
    links: "/auction",
  },
];

export default menus;
